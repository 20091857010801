"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Select = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
function Select(_a) {
    var _b = _a.dataId, dataId = _b === void 0 ? 'select' : _b, errorMessage = _a.errorMessage, _c = _a.label, label = _c === void 0 ? '' : _c, options = _a.options, fullWidth = _a.fullWidth, _d = _a.width, width = _d === void 0 ? 260 : _d, _e = _a.minWidth, minWidth = _e === void 0 ? 100 : _e, props = __rest(_a, ["dataId", "errorMessage", "label", "options", "fullWidth", "width", "minWidth"]);
    return ((0, jsx_runtime_1.jsxs)(material_1.FormControl, __assign({ id: "".concat(dataId, "Wrp"), fullWidth: fullWidth, size: "small", sx: { minWidth: minWidth, width: !fullWidth ? width : undefined } }, { children: [(0, jsx_runtime_1.jsx)(material_1.InputLabel, __assign({ id: dataId }, { children: label })), (0, jsx_runtime_1.jsx)(material_1.Select, __assign({}, props, { fullWidth: fullWidth, label: label, labelId: dataId }, { children: options.map(function (option) { return ((0, jsx_runtime_1.jsx)(material_1.MenuItem, __assign({ value: option.value }, { children: option.label }), option.value)); }) })), errorMessage && (0, jsx_runtime_1.jsx)(material_1.FormHelperText, __assign({ error: true }, { children: errorMessage }))] })));
}
exports.Select = Select;
