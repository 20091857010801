"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsumerTerroristsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var constants_1 = require("../../../../../constants");
var helpers_1 = require("../../../../../helpers");
var useTableFilters_1 = require("../../../../../hooks/useTableFilters");
var S = __importStar(require("../../../../../styles"));
var download_data_1 = require("../../../../ud-ui/components/download-data");
var filter_values_1 = require("../../../../ud-ui/components/filter-values");
var form_calendar_1 = require("../../../../ud-ui/components/form-calendar");
var DataGrid_1 = require("../../../../ud-ui/components/table/DataGrid");
var consumer_terrorists_1 = require("../../../domain/constants/consumer-terrorists");
var getConsumerTerroristsColumns_1 = require("../../../domain/helpers/getConsumerTerroristsColumns");
var reportsApi_1 = require("../../../store/api/reportsApi");
var defaultFilters = {
    dateSorting: 'desc',
    period: '',
};
var ConsumerTerroristsPage = function () {
    var _a = (0, useTableFilters_1.useTableFilters)(constants_1.LOCATION_KEYS.consumerTerrorists, consumer_terrorists_1.consumerTerroristsFilters, defaultFilters), filters = _a.filters, tableFilters = _a.tableFilters, pagination = _a.pagination, changeTableFilter = _a.changeTableFilter, changeFilter = _a.changeFilter, deleteTableFilter = _a.deleteTableFilter, getTableFiltersParams = _a.getTableFiltersParams, resetPage = _a.resetPage, setTableFilter = _a.setTableFilter, setPageParams = _a.setPageParams;
    var tableParams = getTableFiltersParams();
    var queryParams = __assign(__assign(__assign({}, tableParams), filters), { period: (0, helpers_1.getIsoEndDate)(filters.period), birthDate: (0, helpers_1.getDate)(tableParams.birthDate, constants_1.DateFormats.Date, constants_1.DateFormats.Iso) });
    var consumerTerrorists = (0, reportsApi_1.useFetchConsumerTerroristsQuery)((0, helpers_1.getQueryParams)(__assign(__assign({}, queryParams), (0, helpers_1.getPaginationParams)(pagination))));
    var dataGridProps = React.useMemo(function () {
        var _a, _b, _c, _d;
        var birthDate = tableFilters.birthDate.value;
        return {
            columns: (0, getConsumerTerroristsColumns_1.getConsumerTerroristsColumns)({
                birthDate: typeof birthDate === 'string' ? birthDate : '',
                onSubmit: setTableFilter,
            }),
            rows: (_b = (_a = consumerTerrorists.data) === null || _a === void 0 ? void 0 : _a.rows) !== null && _b !== void 0 ? _b : [],
            rowCount: (_d = (_c = consumerTerrorists.data) === null || _c === void 0 ? void 0 : _c.total) !== null && _d !== void 0 ? _d : 0,
            sortModel: [{ field: 'period', sort: filters.dateSorting }],
        };
    }, [consumerTerrorists.data, tableFilters.birthDate, filters.dateSorting]);
    var handleSortModelChange = React.useCallback(function (values) {
        var _a;
        if ((_a = values[0]) === null || _a === void 0 ? void 0 : _a.sort) {
            changeFilter('dateSorting')(values[0].sort);
            resetPage();
        }
    }, []);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(S.FiltersLayout, __assign({ "data-id": "filterConsumerTerrorists" }, { children: [(0, jsx_runtime_1.jsx)(S.FiltersWrapBlock, { children: (0, jsx_runtime_1.jsx)(form_calendar_1.FormCalendar, { className: "filtersLayout", dataId: "period", isClearable: true, fieldTitle: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u043F\u0435\u0440\u0438\u043E\u0434", fieldValue: filters.period, setFieldValue: changeFilter('period') }) }), (0, jsx_runtime_1.jsx)(S.FiltersBlock, { children: (0, jsx_runtime_1.jsx)(download_data_1.DownloadData, { params: queryParams, type: "consumerTerrorists" }) })] })), (0, jsx_runtime_1.jsx)(filter_values_1.FilterValues, { values: tableFilters, onChange: changeTableFilter, onDelete: deleteTableFilter }), (0, jsx_runtime_1.jsx)(DataGrid_1.UDDataGrid, __assign({}, dataGridProps, pagination, { isLoading: consumerTerrorists.isLoading || consumerTerrorists.isFetching, onSortModelChange: handleSortModelChange, onPageModeChange: setPageParams }))] }));
};
exports.ConsumerTerroristsPage = ConsumerTerroristsPage;
